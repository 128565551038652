import phpStrtr from 'locutus/php/strings/strtr'
import phpNumberFormat from 'locutus/php/strings/number_format'

Number.prototype.format = function() {
  return phpNumberFormat(this)
}

Number.prototype.toCurrency = function() {
  return 'NT. ' + this.format(this)
}

String.prototype.toHalf = function() {
  return phpStrtr(this, 'ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ１２３４５６７８９０＠。', 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890@.')
}

String.prototype.toNumber = function() {
  return parseInt(this.toString().toHalf().replace(/[^\d]/, ''), 10) || 0
}
