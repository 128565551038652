//
// FormSupport ( 0.1.2.0 )
//
import { GtUiComponent } from '../../shared/core/GtUi'

class FormSupport extends GtUiComponent {
  name() {
    return 'FormSupport'
  }

  fetchUi() {
    this.ui.buttons = $('.btn-submit')
    this.ui.foucsInput = $('.input-foucs')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.buttons.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return false }

    if (this.ui.buttons.length > 0) {
      this.ui.buttons.each((_, self) => {
        this.initFormSupport($(self))
      })
    }

    if (this.ui.foucsInput.length == 1) {
      this.ui.foucsInput.focus()
      this.ui.foucsInput.select()
    }

    return true
  }

  destroy() {
    return true
  }

  initFormSupport(uiButton) {
    let uiForm = ''

    if (uiButton.data('form')) {
      uiForm = $(`form.${uiButton.data('form')}`)
    } else {
      uiForm = uiButton.parents('form:eq(0)')
    }

    if (uiForm.length < 0) { return }

    uiButton.on('click', (e) => {
      e.preventDefault()
      uiForm.submit()
    })
  }

}

export default FormSupport