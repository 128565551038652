$.notify.addStyle('ok', {
  html: "<div><span data-notify-text/></div>",
  classes: {
    base: {
      "white-space": "nowrap",
      "color": "white",
      "background-color": "#651FFF",
      "border-bottom": "2px solid #311B92",
      "padding": "0.75em 2em",
      "border-radius": "0.5em",
      "opacity": "0.9",
    }
  }
})

$.notify.addStyle('failed', {
  html: "<div><span data-notify-text/></div>",
  classes: {
    base: {
      "white-space": "nowrap",
      "color": "white",
      "background-color": "#FF1744",
      "border-bottom": "2px solid #D50000",
      "padding": "0.75em 1.5em",
      "border-radius": "0.5em",
    }
  }
})