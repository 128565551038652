import _ from 'lodash'

// 
// GtUi ( 0.1.0.0 )
// 
function GtUi(label, {
  route=null,
  load: {
    before=null,
    components={},
    after=null
  }
}) {
  const debugMode = location.href.indexOf('debug=1') != -1

  if (_.isRegExp(route) && !route.test(location.pathname)) {
    return
  }

  if(_.isFunction(before)) {
    before()
  }

  let initCount = 0

  Object.keys(components).map((objectKey) => {
    try {
      const component = components[objectKey]
      window[objectKey] = new component
      initCount += 1

    } catch (err) {
      const errMsg = err.toString()
      console.error(`GtUi:${label}:loadError:${objectKey}:${errMsg}`)
    }
  })

  if(_.isFunction(after)) {
    after()
  }

  if(debugMode) {
    console.log(`GtUi:${label}:${initCount}`)
  }
}

class GtCommonDomSupport {
  name () {
    return 'GtCommonDomSupport'
  }

  constructor(options) {
    if (options == null) { options = {} }
    this.options   = options
    this.component = this.options.component
    this.mounted   = this.options.mounted
    this.destroy   = this.options.destroy // function disabled

    this.initComponentStatus()
    this.initDomEvent()
  }

  initComponentStatus () {
    return this.component.domStatus = {loaded: false, destroyed: true}
  }

  initDomEvent () {
    return $(() => {
      if ((this.component.domStatus.loaded === false) &&
         (this.component.domStatus.destroyed === true)) {

        if (this.mounted()) { return this.isLoaded() }
      }
    })
  }

  isDestroyed () {
    if (this.component.debug()) {
      console.log(this.component.name() + ':isDestroyed')
    }

    this.component.domStatus.loaded = false
    return this.component.domStatus.destroyed = true
  }

  isLoaded () {
    if (this.component.debug()) {
      console.log(this.component.name() + ':isLoaded')
    }

    this.component.domStatus.loaded = true
    return this.component.domStatus.destroyed = false
  }
}


class GtUiComponent {
  name () {
    return 'GtUiComponent'
  }

  constructor () {
    this.core    = {}
    this.ui      = {}
    this.status  = {}
    this.setting = {}

    new GtCommonDomSupport({
      component: this,
      mounted: () => {
        return this.mounted()
      },
      destroy: () => {
        return this.destroy()
      }
    })
  }

  debug () {
    return location.href.indexOf('debug=1') != -1
  }

  mounted () {
    return true
  }

  destroy () {
    return true
  }
}

export {
  GtUi,
  GtUiComponent,
  GtCommonDomSupport
}