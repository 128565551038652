//
// DeployChecklistEditPage ( 0.1.2.0 )
//
import { GtUiComponent } from '../../shared/core/GtUi'
import axios from 'axios'

class DeployChecklistEditPage extends GtUiComponent {
  name() {
    return 'DeployChecklistEditPage'
  }

  fetchUi() {
    this.ui.inputs = $('input.partial-update')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.inputs.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return false }

    if (this.ui.inputs.length > 0) {
      this.initInputs()
    }

    return true
  }

  destroy() {
    return true
  }

  initInputs() {
    const uiForm = $(this.ui.inputs[0]).parents('form:eq(0)')

    this.ui.inputs.each((_index, el) => {
      const uiInput = $(el)

      if (uiForm.data('readonly')) {
        uiInput.prop('disabled', true)
      } else {
        uiInput.on('click', (e) => {
          this.updateData(uiForm, uiInput)
        })
      }
    })
  }

  updateData(uiForm, uiInput) {
    const formData = new FormData()
    const csrfParams = $('meta[name=csrf-param]').attr('content')
    const csrfToken = $('meta[name=csrf-token]').attr('content')

    formData.append(uiInput.attr('name'), uiInput.val())
    formData.append(csrfParams, csrfToken)

    const actionUrl = `${uiForm.attr('action')}/partial_update`
    axios.post(actionUrl, formData)
         .then(res => {
            if (res.data.result == 'success') {
              $.notify(`項目已更新：${res.data.data.optionValue}`, { style: 'ok' })
            } else {
              $.notify('✘ 更新失敗，請重新再試一次', { style: 'failed' })
            }
         })
         .catch(err => {
            $.notify('✘ 程式發生錯誤，請重新再試一次，或聯絡 IT 人員進行檢查', { style: 'failed' })
            console.error('DeployChecklistEditPage', err)
         })
  }

}

export default DeployChecklistEditPage