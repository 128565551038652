import { GtUi } from '../shared/core/GtUi'

import FormSupport from './components/FormSupport'

GtUi('Global', {
  load: {
    components: {
      FormSupport
    }
  }
})

import DeployChecklistEditPage from './pages/DeployChecklistEditPage'

GtUi('DeployChecklistPage', {
  route: /\/deploy_checklists\//,
  load: {
    components: {
      DeployChecklistEditPage
    }
  }
})

